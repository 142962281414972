console.info('Hello world');
import data from '../assets/data/chat.json';
import createCardCat from '../scripts/chatCreate';
import { OpenBoxFilter } from './filtre';



/// création date 


var dateNav = document.querySelector('#dateFoot').innerHTML = new Date().getFullYear()


/// btn pour l"activation de la nav 

let btnNav = document.querySelector('#btnNav');

btnNav.addEventListener('click', () => {
    document.querySelector('.head').classList.toggle('head--actif')
    document.querySelector('.head__nav').classList.toggle('head__nav--none');
    document.querySelector('.head__btn').classList.toggle('head__btn--actif');
    var dateNav = document.querySelector('#year').innerHTML = new Date().getFullYear()
});

console.log(data[0].nom);
console.log(data);
createCardCat();
OpenBoxFilter();


