
var alpha = 'mon reuf';
import data from '../assets/data/chat.json';
import dataChatton from '../assets/data/chatton.json';

if (window.location.pathname == '/chats.html') {
    var dataMap = data;
    console.log(dataMap);
} else if (window.location.pathname == '/chattons.html') {
    var dataMap = dataChatton;
} else {

}

function cardlayout(srcImg, srcNom, srcSexImg) {

    let txtHtml1 = document.createElement('p');
    let txtHtml2 = document.createElement('p');
    txtHtml1.classList.add('groupeCouple__title');
    txtHtml2.classList.add('groupeCouple__txt');

    let imgbase = document.createElement("img");
    imgbase.classList.add('cardCat__img');
    let boxNameImg = document.createElement('div');
    boxNameImg.classList.add('groupeCouple__box');
    let boxCardCat = document.createElement('div');
    boxCardCat.classList.add('cardCat__box');
    //

    imgbase.src = srcImg;
    txtHtml1.textContent = srcNom;
    txtHtml2.textContent = srcSexImg;
    //
    document.querySelector('.sect__cardCat').append(boxCardCat);
    console.log(document.querySelectorAll('.cardCat__box'));
    document.querySelectorAll('.cardCat__box').forEach(el => {
        el.append(imgbase);
        el.append(boxNameImg);
        console.log(el);
        let imgOverlay = srcImg;
        el.addEventListener('click', () => {
            console.log('im cattt');
            // overlayCatRead(imgOverlay);
            let overlayDiv = document.querySelector('.cardCat__overlayBox');
            overlayDiv.style.display = 'flex';

        })

    })

    document.querySelectorAll('.groupeCouple__box').forEach(el => {
        el.append(txtHtml1, txtHtml2);
    })

}

function overlayCatRead(imgOverlay) {

    let overlayDiv = document.createElement('div');
    //overlayDiv.classList.remove('cardCat__overlayBox--none');
    overlayDiv.classList.add('cardCat__overlayBox');
    overlayDiv.style.top = window.scrollY + "px";
    document.querySelector('.main').append(overlayDiv);
    document.body.style.overflow = "hidden";// a débloquer lors du retrait de l'overlay
    document.querySelector('.head').classList.add('head--none');
    /// creation du contenu 
    let imgbase = document.createElement("img");
    imgbase.classList.add('overlay__img');
    imgbase.src = imgOverlay;
    overlayDiv.append(imgbase);
    // la croix 
    let croixImg = document.createElement('img');
    croixImg.src = "../assets/images/croix.svg";
    croixImg.classList.add('overlay__croix');
    overlayDiv.append(croixImg);

    croixImg.addEventListener('click', () => {
        overlayDiv.classList.remove('cardCat__overlayBox');
        overlayDiv.style.direction = "none";
        document.body.style.overflow = "scroll";// a débloquer lors du retrait de l'overlay
        document.querySelector('.head').classList.remove('head--none');
    })

}

export default function createCardCat() {

    console.log("hohoho");
    console.log(data[0].color);
    //

    dataMap.map(el => {
        let srcImg = el.imgSrc;
        let srcNom = el.nom;
        let srcSexImg = el.sexeImg;
        cardlayout(srcImg, srcNom, srcSexImg);

    });

    let croixImg = document.querySelector('.overlay__croix');
    let overlayDiv = document.querySelector('.cardCat__overlayBox');
    /// ça fonctionne pas ou bien un peux 
    croixImg.addEventListener('click', () => {
        overlayDiv.classList.add('cardCat__overlayBox--none');
        overlayDiv.style.display = 'none';
        document.body.style.overflow = "scroll";// a débloquer lors du retrait de l'overlay
        document.querySelector('.head').classList.remove('head--none');
    });

}

